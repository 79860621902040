import React from 'react'
import { connect } from 'react-redux'

import Section from '../Section'
// import { setLocationValue, setLocationState, clearLocationSuggestions, fetchLocations } from '../../actions/jobDescription'
import { Button } from 'reactstrap'

const JobLocationSection = ({
  isLoadingSuggestions,
  suggestionsFetched,
  value,
  locationState=[],
  dispatch
}) => {
  // console.log('JOB LOCATION PROPS: ', props)
  // const [LocationState, setLocationState] = useState([])

  // useEffect(() => {
  //   if (value)
  //     dispatch(setLocationState([value]))
  // }, [])

  const handleChangeInput = (e, { newValue }) => {
    // dispatch(setLocationValue(newValue))
  }

  const inputProps = {
    value,
    onChange: handleChangeInput,
    placeholder: 'e.g. San Francisco Bay Area',
    disabled: locationState.length > 4
  }

  // const onSuggestionsFetchRequested = useCallback(_.debounce(({ value }) => {
  //   // dispatch(fetchLocations(value))
  // }, 200),
  //   []
  // )

  const onsSuggestionsClearRequested = () => {
    // dispatch(clearLocationSuggestions())
  }
  const onLocationSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
    // if (!locationState.includes(suggestionValue))
    //   dispatch(setLocationState([...locationState, suggestionValue]))

    // dispatch(setLocationValue(''))
  }
  const handleDeleteLocation = (e) => {
    // dispatch(setLocationState([...locationState].filter(lstate => lstate != e.target.value)))
  }

  const renderSuggestion = (suggestion) => {
    return <div>{suggestion}</div>
  }

  // console.log("===========================================", locationState)

  return (
    <Section title='Job Location'>
      <p>Where should the prospects be located at?</p>
      <div className="locationChipsContainer">
        {locationState && locationState.map((chip, i) => <Button
          key={i}
          value={chip}
          className="locationChip"
          color="primary"
          outline
          disabled
          // onClick={handleDeleteLocation}
        >
          {`✓ ${chip}`}
        </Button>)}
      </div>
      {/* <AutoSuggest
        inputProps={inputProps}
        // suggestions={suggestionsFetched}
        // onSuggestionSelected={onLocationSuggestionSelected}
        // onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        // onSuggestionsClearRequested={onsSuggestionsClearRequested}
        // renderSuggestion={renderSuggestion}
        // getSuggestionValue={(suggestion) => suggestion}
      /> */}
    </Section>
  )
}

export default connect(state => ({
  ...state.editJob
}))(JobLocationSection)